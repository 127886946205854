.main {
  @apply w-full max-w-screen-2xl m-auto;
}

.wide {
  @apply px-24;
}

.narrow {
  @apply px-40;
}

@media screen(lg) {
  .wide,
  .narrow {
    @apply px-64;
  }
}

@media screen(xl) {
  .wide,
  .narrow {
    @apply px-96;
  }
}
